class ManageCenterService {
  detectedType(val) {
    switch (val) {
      case "SW":
        return " خدمة النافذة الواحدة ";
      case "SP":
        return "إجراء";
      case "SM":
        return "خدمة المنارة";
      default:
        break;
    }
  }
}
const centerService = new ManageCenterService();
export default centerService;